<template>
  <div
    class="dropdown-container box-shadow__popover u-background-color-white mt-2 py-lg-2"
  >
    <div class="dropdown">
      <nav class="dropdown-nav w-100 h-100">
        <ul class="dropdown-nav__list m-0 px-0 p-lg-0 u-min-height-percent-100">
          <slot></slot>
        </ul>
      </nav>
    </div>
    <div
      v-if="hideOnClickOutside"
      class="dropdown-overlay"
      @click.stop="emit('close')"
    ></div>
  </div>
</template>
<script setup lang="ts">
import { toRefs } from 'vue';

const props = defineProps<{
  hideOnClickOutside?: boolean;
}>();

const { hideOnClickOutside } = toRefs(props);

const emit = defineEmits<{
  (e: 'close'): void;
}>();
</script>
<style scoped lang="scss">
.dropdown-container {
  position: absolute;
  white-space: nowrap;
  z-index: 600;

  @include media-breakpoint-down(lg) {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    box-shadow: none !important;
    border-top: 1px solid $sodra-black-10;
  }

  @include media-breakpoint-up(lg) {
    max-width: 275px;
  }
}

.dropdown-nav {
  @include media-breakpoint-down(lg) {
    position: fixed;
    left: 0;

    &__list {
      display: flex;
      flex-direction: column;
    }
  }
}

.dropdown-overlay {
  background-color: transparent;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  z-index: -1;

  @include media-breakpoint-down(lg) {
    background-color: white;
  }
}
</style>
