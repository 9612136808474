import { format, getMonth } from 'date-fns';
import { sv } from 'date-fns/locale';

const dateOnlyLength = 'YYYY-MM-DD'.length;

export const dateFormats = {
  dateFormat: 'yyyy-LL-dd',
  dateWithTimeFormat: 'yyyy-LL-dd{0}HH:mm',
  timeFormat: 'HH:mm',
  shortDayFormat: 'd',
  shortDayAndMonthFormat: 'd/L',
  shortMonthFormat: 'MMM',
  longMonthYearFormat: 'MMMM yyyy',
  longMonthFormat: 'MMMM',
  yearFormat: 'yyyy',
  yearAndMonthFormat: 'yyyy-LL',
  yearAndWeekFormat: "'v.'w yyyy",
  shortYearAndWeekFormat: "'v.'w -yy",
};

export function formatDateWithFormat(
  value: Date | string | undefined | null,
  dateFormat: string,
): string {
  return value ? format(new Date(value), dateFormat, { locale: sv }) : '';
}

export function formatDate(value: Date | string | undefined | null): string {
  return formatDateWithFormat(value, dateFormats.dateFormat);
}

export function formatYearMonth(
  value: Date | string | undefined | null,
): string {
  return formatDateWithFormat(value, dateFormats.yearAndMonthFormat);
}

export function formatDateWithTime(
  value: Date | string | undefined | null,
  separator?: string,
): string {
  return formatDateWithFormat(
    value,
    dateFormats.dateWithTimeFormat.replace('{0}', separator ?? ' '),
  );
}

export function formatTime(value: Date | string | undefined | null): string {
  return formatDateWithFormat(value, dateFormats.timeFormat);
}

export function formatShortDay(value: string) {
  return formatDateWithFormat(value, dateFormats.shortDayFormat);
}

export function formatDayMonth(value: string) {
  return `${formatShortDay(value)} ${getMonthShortNameByNumber(
    getMonth(new Date(value)) + 1, // Offset by 1 since date-fns returns month in range 0-11
  )}`;
}

export function formatYear(value: string | undefined): string {
  return formatDateWithFormat(value, dateFormats.yearFormat);
}

export function getMonthsBetweenDates(d1: Date, d2: Date) {
  let months = 0;
  months = (d2.getFullYear() - d1.getFullYear()) * 12;
  months -= d1.getMonth();
  months += d2.getMonth();
  return months <= 0 ? 0 : months;
}

export function getDaysBetweenDates(d1: Date, d2: Date) {
  return Math.floor(getMinutesBetweenDates(d1, d2) / 60 / 24);
}

export function getMinutesBetweenDates(startDate: Date, endDate: Date): number {
  const diff = endDate.getTime() - startDate.getTime();
  return diff / 60000;
}

export function getLongMonthYearDate(date: string | undefined) {
  return date
    ? format(new Date(date), dateFormats.longMonthYearFormat, {
        locale: sv,
      })
    : '';
}

/**
 * Returns a long month name for the given month number, where 1 represents January and 12 represents December.
 * @param number The month number
 * @returns The long month name of the given month number,
 */
export function getLongMonthName(number: number): string | null {
  if (number > 12 || number < 1) {
    return null;
  }

  const date = new Date();
  date.setMonth(number - 1);
  return format(date, dateFormats.longMonthFormat, {
    locale: sv,
  });
}

/**
 * Gets the short month name string for the given number, optionally with a year appended to the end of the string.
 * @param number The month number
 * @param year The year, optional.
 * @returns If a year is given, returns the short month name plus the year, e.g. "Jan 2021". Otherwise return the short month name, e.g. "Jan".
 */
export function getDateShortName(
  number: number,
  year: number | null = null,
): string | null {
  const monthString = getMonthShortNameByNumber(number);

  if (!monthString) {
    return null;
  }

  if (year) {
    return monthString + ' ' + year;
  } else {
    return monthString;
  }
}

/**
 * Returns a short month name for the given month number, where 1 represents January and 12 represents December.
 * @param number The month number
 * @returns The short month name of the given month number,
 */
export function getMonthShortNameByNumber(number: number): string | null {
  switch (number) {
    case 1:
      return 'Jan';
    case 2:
      return 'Feb';
    case 3:
      return 'Mar';
    case 4:
      return 'Apr';
    case 5:
      return 'Maj';
    case 6:
      return 'Jun';
    case 7:
      return 'Jul';
    case 8:
      return 'Aug';
    case 9:
      return 'Sep';
    case 10:
      return 'Okt';
    case 11:
      return 'Nov';
    case 12:
      return 'Dec';
  }
  return null;
}

export function getDateOnlyWithLocalTimezone(value: string): Date | undefined {
  if (value.length !== dateOnlyLength) {
    return undefined;
  }
  return new Date(`${value}T00:00:00`);
}
