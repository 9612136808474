<template>
  <MCompactHeader
    :logo-url="logoUrl"
    :logo-route-name="RouteNames.Home"
    class="d-print-none"
  >
    <template #headerStart>
      <MSodraHeaderLogo class="d-none d-xl-inline" />

      <PageHeaderProfile
        v-if="showProfileOnDropdownMobile && customer"
        class="d-lg-none not-clickable"
        :customer="customer"
        override-mobile
      />
    </template>

    <template #headerEnd>
      <a
        href="https://www.sodra.com"
        class="o-button u-float-left me-3 d-none d-lg-inline-flex u-border-color-black-15"
      >
        {{ t('header.to_sodra') }}
      </a>

      <template v-if="customer">
        <PageHeaderProfile
          @click="toggleProfileMenu"
          :customer="customer"
          :desktop-only="showProfileDropdown"
        >
          <HeaderDropdownNavList
            v-if="showProfileDropdown"
            :customer="customer"
            @close="showProfileDropdown = false"
          />
        </PageHeaderProfile>

        <template v-if="showProfileDropdown">
          <MMaterialSymbol
            class="d-xl-none clickable"
            :class="{ 'd-none': menuIconOnDesktop }"
            size="24"
            :symbol-name="MaterialSymbolNames.Close"
            @click="toggleProfileMenu"
          />
        </template>
      </template>
    </template>
  </MCompactHeader>
</template>

<script setup lang="ts">
import { MaterialSymbolNames } from '@/models/MaterialSymbolNames';
import { toRefs, computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import MCompactHeader from '@/components/common/header/MCompactHeader.vue';
import MSodraHeaderLogo from '@/components/common/icons/MSodraHeaderLogo.vue';
import PageHeaderProfile from './PageHeaderProfile.vue';
import HeaderDropdownNavList from '@/components/header/HeaderDropdownNavList.vue';
import MMaterialSymbol from '@/components/common/icons/MMaterialSymbol.vue';
import { StyleBreakpoints } from '@/models/StyleBreakpoints';
import { RouteNames } from '@/router/routeNames';
import { useAuthStore } from '@/stores/authStore';
import type { Customer } from '@/features/sodraEl/models/Customer';

const { t } = useI18n();
const authStore = useAuthStore();

const props = defineProps<{
  screenSize: number;
}>();

const { screenSize } = toRefs(props);

const showProfileDropdown = ref<boolean>(false);

const customer = computed<Customer | null>(() => {
  return authStore.customer;
});

const menuIconOnDesktop = computed<boolean>(() => {
  return (
    screenSize.value >= StyleBreakpoints.Lg &&
    screenSize.value < StyleBreakpoints.Xl
  );
});

const showProfileOnDropdownMobile = computed<boolean>(() => {
  return !!customer.value && showProfileDropdown.value;
});

const logoUrl = computed<string | undefined>(() => {
  return !showProfileDropdown.value || menuIconOnDesktop.value
    ? '/img/sodra-logo.svg'
    : undefined;
});

function toggleProfileMenu(): void {
  showProfileDropdown.value = !showProfileDropdown.value;
}
</script>
<style scoped lang="scss">
.u-border-color-black-15 {
  border-color: $sodra-black-15 !important;
}
</style>
