import type { Customer } from '@/features/sodraEl/models/Customer';
import authService from '@/services/AuthService';
import { Logger } from '@/utils/Logger';
import { defineStore } from 'pinia';
import { CommonStore } from './common/CommonStore';

export const authStoreId = 'auth';

export type AuthError = 'NotFound' | 'Other';

interface State {
  isAuthenticated: boolean | undefined;
  authenticationError: AuthError | null;
  common: CommonStore<Customer>;
}

export const useAuthStore = defineStore(authStoreId, {
  state: (): State => ({
    isAuthenticated: undefined,
    authenticationError: null,
    common: new CommonStore<Customer>({
      freshMinutes: 30,
    }),
  }),

  getters: {
    customer(): Customer | null {
      return this.common.item.data;
    },
  },

  actions: {
    async login(): Promise<void> {
      Logger.debug('Starting login');
      return authService.login().then((response) => {
        window.location.replace(response.redirectUrl);
      });
    },

    async authenticationCallback(grandidsession: string): Promise<void> {
      Logger.debug('Authentication callback');
      return authService
        .callback(grandidsession)
        .then((response) => {
          this.isAuthenticated = true;
          this.authenticationError = null;
          this.common.setLoadingComplete({ data: response });
        })
        .catch((error) => {
          Logger.warn('Authentication callback error', error);
          this.isAuthenticated = false;
          this.authenticationError =
            error?.response.status === 404 ? 'NotFound' : 'Other';
        });
    },

    async getCustomer(): Promise<Customer> {
      if (this.common.hasFreshData() && this.common.item.data !== null) {
        Logger.warn('Sodra el customer already loaded', this.common.item.data);
        return Promise.resolve(this.common.item.data);
      }

      const currentlyLoadingPromise = this.common.currentlyLoadingPromise();
      if (currentlyLoadingPromise !== null) {
        return currentlyLoadingPromise;
      }

      const loadingPromise = authService
        .getCurrentCustomer()
        .then((customer) => {
          Logger.debug(`Sodra el loaded customer successfully`, customer);
          this.isAuthenticated = true;
          this.common.setLoadingComplete({ data: customer });
          return customer;
        })
        .catch((error) => {
          Logger.debug('Sodra el failed to load customer', error);
          this.isAuthenticated = false;
          this.common.setLoadingError({ error });
          return Promise.reject(error);
        });

      this.common.setLoading({ loadingPromise });
      return loadingPromise;
    },

    async logout(): Promise<void> {
      Logger.debug('Starting sign out, return url', window.location.origin);
      return authService.logout().then(() => {
        this.isAuthenticated = false;
        this.common.clearData();
        this.common.clearLoading();
      });
    },
  },
});
