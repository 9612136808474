import { useAuthStore } from '@/stores/authStore';
import { EventBus, EventLogin } from '@/utils/EventBus';
import { Logger } from '@/utils/Logger';
import type { RouteLocationNormalized } from 'vue-router';
import { RouteNames } from '../routeNames';
import { Permissions, ValidationError } from '@/models/permissions/Permissions';

export async function authorizationGuard(to: RouteLocationNormalized) {
  const anyRouteMatch = to.matched.length;
  const authStore = useAuthStore();

  if (!anyRouteMatch && !authStore.isAuthenticated) {
    Logger.debug(
      'No route match, but since not authenticated we might just not have the matching route yet, so triggering login.',
      to,
    );
    EventBus.emit(EventLogin);
    return false;
  }

  const authRequirements = to.meta.authRequirements;

  if (authRequirements) {
    const validationResult = Permissions.validate(authRequirements);
    if (!validationResult.valid) {
      Logger.debug('Auth requirement validation failed.', authRequirements);
      if (validationResult.error === ValidationError.NotAuthenticated) {
        EventBus.emit(EventLogin);
      } else {
        return {
          name: RouteNames.Home,
        };
      }

      return false;
    }
  }
}
