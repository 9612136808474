<template>
  <div>
    <router-link
      :to="{ name: RouteNames.Home }"
      class="c-compact-header__logo-link d-flex"
    >
      <img
        src="/img/sodra-logo.svg"
        class="c-compact-header__logo-image"
        :alt="t('alts.sodraLogo')"
      />
    </router-link>
  </div>
</template>
<script setup lang="ts">
import { RouteNames } from '@/router/routeNames';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
</script>
