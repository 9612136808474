import mitt, { type Emitter } from 'mitt';

export const EventLogin = 'EventLogin';
export const EventLogout = 'EventLogout';

type GlobalEvents = {
  EventLogin: void;
  EventLogout: void;
};

export const EventBus: Emitter<GlobalEvents> = mitt<GlobalEvents>();
