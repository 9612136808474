import type { Breadcrumb } from '@/models/Breadcrumb';
import {
  createRouter,
  createWebHistory,
  type RouteRecordRaw,
} from 'vue-router';
import { nextTick } from 'vue';
import { type AuthRequirements } from './authRequirements';
import {
  AuthenticationRouteDefinition,
  HomeRouteDefinition,
  NotFoundRouteDefinition,
} from './routes';
import i18n from '@/i18n';
import { startupGuard } from './guards/startupGuard';
import { authorizationGuard } from './guards/authGuard';

declare module 'vue-router' {
  interface RouteMeta {
    track?: boolean;
    name?: string;
    title?: string;
    authRequirements?: AuthRequirements;
  }
}

const defaultDocumentTitle = import.meta.env.VITE_APP_DOCUMENT_DEFAULT_TITLE;
const baseDocumentTitle = import.meta.env.VITE_APP_DOCUMENT_BASE_TITLE;

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  linkExactActiveClass: 'active',
  linkActiveClass: 'open',
  routes: [
    HomeRouteDefinition,
    AuthenticationRouteDefinition,
    NotFoundRouteDefinition,
  ],
});

const routes: RouteRecordRaw[] = [];

// The router.beforeEach takes care of checking if no route match, and not authenticated,
// then login is triggered (so that member profile can be loaded).
// Router guards are executed in order of creation. https://router.vuejs.org/guide/advanced/navigation-guards.html#Global-Before-Guards
router.beforeEach(async (to) => await startupGuard(to));
router.beforeEach(async (to) => await authorizationGuard(to));

router.afterEach((route) => {
  // Update document title.
  let documentTitle = baseDocumentTitle;
  if (route.meta.title) {
    documentTitle += i18n.global.te(route.meta.title)
      ? i18n.global.t(route.meta.title)
      : route.meta.title;
  } else {
    documentTitle += defaultDocumentTitle;
  }

  nextTick(() => {
    document.title = documentTitle;
  });
});

export function getPathFromBreadcrumbs(breadcrumbs: Breadcrumb[]): string {
  let path = '';
  breadcrumbs.forEach((breadcrumb) => {
    path += breadcrumb.route + '/';
  });

  return path;
}

export { router, routes };
