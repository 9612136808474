import { useStartupStore } from '@/stores/startupStore';
import { type RouteLocationNormalized } from 'vue-router';
import { RouteNames } from '../routeNames';
import { Logger } from '@/utils/Logger';
import { useAuthStore } from '@/stores/authStore';

/**
 * Initialize the application by loading the member profile and initialize auth.
 * @returns A navigation result that can be used to cancel navigation.
 */
export async function startupGuard(
  to: RouteLocationNormalized,
): Promise<boolean> {
  const anyRouteMatch = to.matched.length;

  Logger.debug(
    'Router beforeEach checking route.',
    to,
    'anyRouteMatch',
    anyRouteMatch,
  );

  const startupStore = useStartupStore();
  if (startupStore.initialized) {
    return true;
  }

  const authStore = useAuthStore();
  if (to.name !== RouteNames.Authentication) {
    await authStore.getCustomer().catch((error) => {
      if (error?.response?.status !== 401) {
        startupStore.error();
      }
    });
  }

  startupStore.isInitialized();

  return true;
}
