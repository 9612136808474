import { defineRule } from 'vee-validate';
import {
  required,
  email,
  max,
  regex,
  integer,
  min_value,
} from '@vee-validate/rules';

export default {
  install: () => {
    defineRule('required', required);
    defineRule('email', email);
    defineRule('max', max);
    defineRule('regex', regex);
    defineRule('integer', integer);
    defineRule('minValue', min_value);

    defineRule(
      'custom',
      (
        value: any,
        [func]: Array<
          (value: any) => boolean | string | Promise<string | boolean>
        >,
      ) => {
        return func(value);
      },
    );

    const socialSecurityNumberRegex =
      /^(?:(?:19|20)[0-9]{2})(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])(\d{4})$/;

    defineRule('ssn', (value: string) => {
      if (!value || !value.length) {
        return true;
      }

      if (!socialSecurityNumberRegex.test(value)) {
        return false;
      }

      let sum = 0;
      const values = value.substring(2, 11);
      for (let i = 0; i < values.length; i++) {
        let v = parseInt(values[i]);
        v *= 2 - (i % 2);

        if (v > 9) {
          v -= 9;
        }
        sum += v;
      }

      const checkValue = Math.ceil(sum / 10) * 10 - sum;

      return value.endsWith(checkValue.toString());
    });

    const phoneNumberRegex = /^[0-9]{1}[0-9]{6,9}$/;

    defineRule('phoneNumber', (value: string) => {
      if (!value) {
        return true;
      }

      return phoneNumberRegex.test(value);
    });

    const phoneNumberCountryCodeRegex = /^\+[0-9]{1,4}$/;

    defineRule('phoneNumberCountryCode', (value: string) => {
      if (!value || !value.length) {
        return true;
      }

      return phoneNumberCountryCodeRegex.test(value);
    });
  },
};
