<template>
  <i
    class="material-symbols-outlined"
    :class="[
      'material-symbols-outlined--' + size,
      { 'material-symbols-filled': filled },
    ]"
  >
    {{ symbolName }}
  </i>
</template>
<script setup lang="ts">
import { toRefs } from 'vue';

export interface MMaterialSymbolProps {
  size: string;
  symbolName: string;
  filled?: boolean;
}

const props = defineProps<MMaterialSymbolProps>();

const { size, symbolName, filled } = toRefs(props);
</script>

<style scoped lang="scss">
.material-symbols-filled {
  font-variation-settings:
    'FILL' 1,
    'wght' 400,
    'GRAD' 0,
    'opsz' 24;
}
</style>
