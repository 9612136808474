import { Config } from './Config';

class LoggerDefinition {
  private isDebugEnabled = true;
  private isWarnEnabled = true;
  private isInformationEnabled = true;
  private isErrorEnabled = true;

  public initialize(): void {
    const logLevel = Config.Settings.LogLevel;

    switch (logLevel) {
      case 'error':
        this.isDebugEnabled = false;
        this.isInformationEnabled = false;
        this.isWarnEnabled = false;
        break;
      case 'warn':
        this.isDebugEnabled = false;
        this.isInformationEnabled = false;
        break;
      case 'info':
        this.isDebugEnabled = false;
        break;
      case 'none':
        this.isDebugEnabled = false;
        this.isInformationEnabled = false;
        this.isWarnEnabled = false;
        this.isErrorEnabled = false;
        break;
      case 'debug':
      default:
        break;
    }
  }

  /* eslint-disable no-console */
  public info(message?: any, ...optionalParams: any[]) {
    if (this.isInformationEnabled && console && console.info) {
      console.info(message, ...optionalParams);
    }
  }

  public warn(message?: any, ...optionalParams: any[]) {
    if (this.isWarnEnabled && console && console.warn) {
      console.warn(message, ...optionalParams);
    }
  }

  public debug(message?: any, ...optionalParams: any[]) {
    if (this.isDebugEnabled && console && console.log) {
      console.log(message, ...optionalParams);
    }
  }

  public error(message?: any, ...optionalParams: any[]) {
    if (this.isErrorEnabled && console && console.error) {
      console.error(message, ...optionalParams);
    }
  }
  /* eslint-enable no-console */
}

export const Logger = new LoggerDefinition();
