export class StyleBreakpoints {
  public static readonly Sm = 580;
  public static readonly Md = 768;
  public static readonly Lg = 992;
  public static readonly Xl = 1260;
  public static readonly Xxl = 1400;
  public static readonly PageSidebarWidth = 390;
}

export type StyleBreakpointName = 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
