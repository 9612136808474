<template>
  <FullscreenLoader v-if="!initialized" />
  <div class="c-fixed-button-bar c-fixed-button-bar__contact d-print-none">
    <a :href="contactLink" target="_blank" :title="t('contactLink.title')">
      <MMaterialSymbol
        :symbol-name="MaterialSymbolNames.Phone"
        size="24"
      ></MMaterialSymbol>
      <MMaterialSymbol
        :symbol-name="MaterialSymbolNames.Email"
        size="24"
      ></MMaterialSymbol>
    </a>
    <MMaterialSymbol
      :symbol-name="MaterialSymbolNames.Cookies"
      :title="t('cookieButton.title')"
      size="24"
      class="clickable"
      @click="renewCookieConsent()"
    ></MMaterialSymbol>
  </div>

  <PageHeader :screen-size="screenSize" />

  <VideoModal />

  <main class="d-flex flex-column content">
    <div v-if="showRouterView" class="u-position-relative flex-fill">
      <router-view></router-view>
    </div>

    <template v-if="initialized && commonError">
      <MBanner image-url="/img/hero_highres-min.jpg" />
      <div class="container u-text-center mt-5">
        <div class="row">
          <div class="col-12 u-text-center">
            <h4 v-html="t('app.error.title')"></h4>
            <p>{{ t('app.error.info') }}</p>
          </div>
        </div>
      </div>
    </template>
  </main>
</template>

<script setup lang="ts">
import '@/assets/scss/main.scss';
import { nextTick, onBeforeUnmount, onMounted, computed, ref } from 'vue';
import Unity from '@sodraskog/unity/scripts/unity';
import { Config } from './utils/Config';
import { EventBus, EventLogin, EventLogout } from './utils/EventBus';
import MBanner from './components/common/MBanner.vue';
import VideoModal from './components/VideoModal.vue';
import PageHeader from './components/header/PageHeader.vue';
import MMaterialSymbol from './components/common/icons/MMaterialSymbol.vue';
import { Logger } from './utils/Logger';
import { useI18n } from 'vue-i18n';
import { useStartupStore } from './stores/startupStore';
import { MaterialSymbolNames } from './models/MaterialSymbolNames';
import FullscreenLoader from './components/FullscreenLoader.vue';
import { useAuthStore } from './stores/authStore';
import { RouteNames } from './router/routeNames';
import { useRouter } from 'vue-router';

const { t } = useI18n();
const router = useRouter();
const startupStore = useStartupStore();
const authStore = useAuthStore();

const contactLink = Config.Settings.ContactLink;

const screenSize = ref<number>(0);

const initialized = computed<boolean>(() => {
  return startupStore.initialized;
});

const showRouterView = computed<boolean>(() => {
  return startupStore.initialized && !startupStore.initializeError;
});

const commonError = computed<boolean>(() => {
  return startupStore.initializeError;
});

onMounted(() => {
  nextTick(() => {
    new Unity(document);
    Logger.debug('Setup Unity');
  });
  updateScreenSize();
  window.addEventListener('resize', updateScreenSize);
});

onBeforeUnmount(() => {
  EventBus.off(EventLogin, login);
  EventBus.off(EventLogout, logout);

  window.removeEventListener('resize', updateScreenSize);
});

function updateScreenSize(): void {
  screenSize.value = window.innerWidth;
}

function logout(): void {
  authStore.logout().then(() => {
    router.replace({ name: RouteNames.Home });
  });
}

function login(): void {
  authStore.login();
}

function renewCookieConsent(): void {
  const cookieConsent = (window as any).CookieConsent;
  if (cookieConsent) {
    cookieConsent.renew();
  }
}

EventBus.on(EventLogin, login);
EventBus.on(EventLogout, logout);
</script>
