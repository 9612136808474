<template>
  <div data-hj-suppress>
    <button
      class="row c-compact-header__button-container g-0"
      :class="{ 'd-none d-lg-flex': desktopOnly }"
    >
      <div
        class="col-auto c-compact-header__button c-compact-header__button--yellow"
      >
        <div class="c-compact-header__button-icon d-flex">
          <span class="t-label-large u-text-uppercase m-auto">
            {{ initials }}
          </span>
        </div>
      </div>

      <div
        class="col d-xxs-none ms-2_5 me-3 u-ellipsis"
        :class="{ 'd-none d-lg-inline': !overrideMobile }"
        data-hj-suppress
      >
        <div
          class="d-block u-color-black t-label-medium u-text-left u-truncate"
        >
          {{ customer.name }}
        </div>
        <div
          class="t-alt-label-medium w-100 u-text-left text-lowercase u-truncate"
        >
          {{ customer.email }}
        </div>
      </div>

      <MMaterialSymbol
        class="d-none d-lg-flex col-auto my-auto"
        size="20"
        :symbol-name="MaterialSymbolNames.ArrowDown"
      />
    </button>
    <slot></slot>
  </div>
</template>
<script setup lang="ts">
import { computed, toRefs } from 'vue';
import { MaterialSymbolNames } from '@/models/MaterialSymbolNames';
import MMaterialSymbol from '@/components/common/icons/MMaterialSymbol.vue';
import type { Customer } from '@/features/sodraEl/models/Customer';

const props = defineProps<{
  customer: Customer;
  desktopOnly?: boolean;
  overrideMobile?: boolean;
}>();

const { customer, desktopOnly, overrideMobile } = toRefs(props);

const initials = computed<string>(() => {
  const fullInitials = (customer.value.name ?? '').split(' ').map((x) => x[0]);

  return fullInitials.length > 1
    ? `${fullInitials.at(0)}${fullInitials.at(-1)}`
    : fullInitials.join('');
});
</script>
<style scoped lang="scss">
.c-compact-header__button {
  white-space: unset;
  &-icon {
    height: 30px;
  }
  &-container {
    line-height: 0 !important;
  }
}
.c-compact-header__button-container {
  @media (min-width: $unity-lg-breakpoint) {
    max-width: 28vw;
  }
}

.proxy-icon {
  position: absolute;
  top: auto;
  left: auto;
  padding: 0.125rem;

  @include media-breakpoint-up(xl) {
    right: -8px;
    bottom: -12px;
  }

  @include media-breakpoint-down(xl) {
    right: -8px;
    bottom: -12px;
    font-size: 16px !important;
  }
}
</style>
