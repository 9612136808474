import type { Settings } from '@/models/Settings';

class ConfigDefinition {
  public Settings: Settings = {
    LogLevel: 'debug',
    ContactLink: '',
    MemberPortalLink: '',
    SodraEl: {
      CustomerServiceEmail: '',
      SodraElUrl: '',
    },
  };

  public get isDevelopment(): boolean {
    return import.meta.env.DEV;
  }

  public initialize(): void {
    this.Settings = (window as any).SodraElPortalSettings;
  }
}

export const Config = new ConfigDefinition();
