<template>
  <header class="c-compact-header">
    <div
      class="c-compact-header__content-container container-fluid u-border-bottom u-border-color-opacity-8 box-shadow-lg-up__compact-header mw-100 px-4 px-xl-5"
    >
      <div class="c-compact-header__content c-compact-header__content--left">
        <slot name="headerStart"></slot>
      </div>

      <div class="c-compact-header__content c-compact-header__content--center">
        <router-link
          v-if="logoUrl && logoRouteName"
          :to="{ name: logoRouteName }"
          class="d-xl-none c-compact-header__logo-link"
        >
          <img :src="logoUrl" class="c-compact-header__logo-image" alt="logo" />
        </router-link>

        <slot name="headerCenter"></slot>
      </div>

      <div class="c-compact-header__content c-compact-header__content--right">
        <slot name="headerEnd"></slot>
      </div>
    </div>
  </header>
</template>

<script setup lang="ts">
import { toRefs } from 'vue';

const props = defineProps<{
  logoUrl?: string;
  logoRouteName?: string;
}>();

const { logoUrl, logoRouteName } = toRefs(props);
</script>
<style scoped lang="scss">
.u-border-color-opacity-8 {
  border-color: $sodra-black-opacity-8 !important;
}
</style>
