import type { ItemWithLoadingStatus } from './ItemWithLoadingStatus';
import { getMinutesBetweenDates } from '@/utils/Dates';

interface Item<T> extends ItemWithLoadingStatus<T> {
  id?: any;
}

export class CommonStore<T> {
  public item: Item<T> = {
    data: null,
    loading: {
      isLoading: false,
      isError: false,
      error: undefined,
      loadingPromise: null,
      loadDate: null,
    },
  };

  private settings: { freshMinutes: number };

  constructor(settings: { freshMinutes: number }) {
    this.settings = settings;
  }

  // -----------------
  // ### Mutations ###
  // -----------------

  public setLoading(payload: { id?: any; loadingPromise: Promise<any> }): void {
    this.item.data = null;
    this.item.id = payload.id;

    this.item.loading.isLoading = true;
    this.item.loading.isError = false;
    this.item.loading.error = undefined;
    this.item.loading.loadingPromise = payload.loadingPromise;
  }

  public setLoadingComplete(payload: { id?: any; data: T }): void {
    this.item.data = payload.data;
    this.item.id = payload.id;

    this.item.loading.isLoading = false;
    this.item.loading.isError = false;
    this.item.loading.error = undefined;
    this.item.loading.loadDate = new Date();
  }

  public setStaleData(): void {
    this.item.loading.isLoading = false;
    this.item.loading.isError = false;
    this.item.loading.error = undefined;
    this.item.loading.loadDate = null;
  }

  public setLoadingError(payload?: { id?: any; error?: any }): void {
    this.item.data = null;
    this.item.id = payload?.id;

    this.item.loading.isLoading = false;
    this.item.loading.isError = true;
    this.item.loading.error = payload?.error;
    this.item.loading.loadDate = null;
  }

  public clearData() {
    this.item.data = null;
    this.item.id = null;
  }

  public clearLoading(): void {
    this.item.loading.isLoading = false;
    this.item.loading.isError = false;
    this.item.loading.error = null;
  }

  //
  // Helper functions
  //
  public hasFreshData(id?: any): boolean {
    if (this.item.id !== id) {
      return false;
    }

    return this.checkIsDataFresh();
  }

  public currentlyLoadingPromise(id?: any): Promise<T> | null {
    if (
      this.item.id === id &&
      this.item.loading.isLoading &&
      this.item.loading.loadingPromise !== null
    ) {
      return this.item.loading.loadingPromise;
    }

    return null;
  }

  private checkIsDataFresh(): boolean {
    return (
      this.item.data !== null &&
      this.item.loading.loadDate !== null &&
      getMinutesBetweenDates(this.item.loading.loadDate, new Date()) <=
        this.settings.freshMinutes
    );
  }
}
