export function poll<T>(
  originalPromise: () => Promise<T>,
  config: {
    intervalInMs: number;
    maxTimes?: number;
  },
  pollCondition?: (response: T) => {
    continuePolling: boolean;
    newIntervalInMs?: number;
  },
  pollingTimes: number = 0,
): Promise<T> {
  return originalPromise()
    .then((response) => {
      const pollConditionResult = pollCondition
        ? pollCondition(response)
        : undefined;
      if (
        pollConditionResult === undefined ||
        pollConditionResult.continuePolling
      ) {
        if (
          config.maxTimes !== undefined &&
          pollingTimes !== undefined &&
          pollingTimes >= config.maxTimes
        ) {
          return Promise.reject('Max polling timeout');
        }

        const interval =
          pollConditionResult?.newIntervalInMs ?? config.intervalInMs;

        return new Promise<T>((resolve, reject) => {
          setTimeout(
            () =>
              poll(
                originalPromise,
                config,
                pollCondition,
                pollingTimes ? pollingTimes + 1 : 1,
              )
                .then(resolve)
                .catch(reject),
            interval,
          );
        });
      } else {
        return response;
      }
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}
