import type { App } from 'vue';
import HighchartsVue from 'highcharts-vue';
import Highcharts from 'highcharts';
import highchartsMore from 'highcharts/highcharts-more';
import accessibility from 'highcharts/modules/accessibility';
import solidGauge from 'highcharts/modules/solid-gauge';

export default {
  install: (app: App) => {
    // There is an issue with type definitions for highcharts-vue with Vite (that's why using "as any" below).
    // See: https://github.com/highcharts/highcharts-vue/issues/219#issuecomment-1199171132
    // and: https://github.com/highcharts/highcharts-vue/issues/209
    app.use(HighchartsVue as any);

    accessibility(Highcharts);
    highchartsMore(Highcharts);
    solidGauge(Highcharts);

    Highcharts.setOptions({
      chart: {
        style: {
          fontFamily: '"Source Sans Pro", Arial, sans-serif', // Unity 4 fonts
        },
      },
    });
  },
};
