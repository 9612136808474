import { createI18n } from 'vue-i18n';

import svGlobal from '@/i18n/locales/sv.json';
import svSodraEl from '@/features/sodraEl/i18n/locales/sv.json';

const sv = {
  ...svGlobal,
  ...svSodraEl,
};

const defaultLocale = 'sv';

export type MessageSchema = typeof sv;

const i18n = createI18n<[MessageSchema], 'sv'>({
  legacy: false,
  locale: defaultLocale,
  fallbackLocale: defaultLocale,
  messages: {
    sv: sv,
  },
});

export default i18n;
