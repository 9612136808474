import Axios, {
  type AxiosError,
  type AxiosInstance,
  type AxiosRequestConfig,
} from 'axios';
import { poll } from '@/utils/Polling';
import { EventBus, EventLogout } from '@/utils/EventBus';

export abstract class BaseServiceDefinition {
  private PollingIntervalHeader = 'x-polling-interval';

  private get apiBaseUrl(): string {
    return import.meta.env.VITE_API_BASE_URL;
  }

  private axiosInstance: AxiosInstance;

  constructor(intercept401: boolean = false) {
    const baseUrl = this.apiBaseUrl;

    this.axiosInstance = Axios.create({
      headers: {
        Pragma: 'no-cache',
        'Cache-Control': 'no-cache',
      },
      withCredentials: true,
      baseURL: baseUrl,
    });

    if (intercept401) {
      this.axiosInstance.interceptors.response.use(
        (response) => {
          return response;
        },
        (error: AxiosError | undefined) => {
          if (error?.response?.status === 401) {
            EventBus.emit(EventLogout);
          }
          return Promise.reject(error);
        },
      );
    }
  }

  protected get(url: string): Promise<any> {
    return this.axiosInstance.get(url, {
      paramsSerializer: {
        indexes: null,
      },
    });
  }

  protected post(url: string, data?: any, params?: any): Promise<any> {
    return this.axiosInstance.post(url, data, { params });
  }

  protected patch(url: string, data?: any): Promise<any> {
    return this.axiosInstance.patch(url, data);
  }

  protected put(url: string, data: any, params?: any): Promise<any> {
    return this.axiosInstance.put(url, data, { params });
  }

  protected delete(url: string, params?: any): Promise<any> {
    return this.axiosInstance.delete(url, {
      params,
      paramsSerializer: {
        indexes: null,
      },
    });
  }

  protected sleep(ms: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  protected downloadFile(
    url: string,
    filename: string,
    polling?: boolean,
  ): Promise<void> {
    const config: AxiosRequestConfig = {
      responseType: 'blob',
    };
    const getPromise = polling
      ? poll(
          () => this.axiosInstance.get(url, config),
          { intervalInMs: 5000 }, // just a default, will be overriden by newIntervalInMs below
          (response) => {
            if (response.status === 202) {
              const pollingInterval = Number(
                response.headers[this.PollingIntervalHeader],
              );

              if (isNaN(pollingInterval)) {
                throw `Unreadable polling interval header: ${response.headers[this.PollingIntervalHeader]}`;
              }

              return {
                continuePolling: true,
                newIntervalInMs: pollingInterval * 1000,
              };
            } else {
              return { continuePolling: false };
            }
          },
        )
      : this.axiosInstance.get(url, config);

    return getPromise.then((response) => {
      const blob = new Blob([response.data], { type: 'application/pdf' });

      const blobURL =
        window.URL && window.URL.createObjectURL
          ? window.URL.createObjectURL(blob)
          : window.webkitURL.createObjectURL(blob);

      const tempLink = document.createElement('a');
      tempLink.style.display = 'none';
      tempLink.href = blobURL;
      tempLink.setAttribute('download', filename);

      // Safari thinks _blank anchor are pop ups. We only want to set _blank
      // target if the browser does not support the HTML5 download attribute.
      // This allows you to download files in desktop safari if pop up blocking
      // is enabled.
      if (typeof tempLink.download === 'undefined') {
        tempLink.setAttribute('target', '_blank');
      }

      document.body.appendChild(tempLink);
      tempLink.click();

      // Fixes "webkit blob resource error 1"
      window.setTimeout(() => {
        document.body.removeChild(tempLink);
        window.URL.revokeObjectURL(blobURL);
      }, 200);
    });
  }
}
