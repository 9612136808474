<template>
  <MDropdownNavList
    hide-on-click-outside
    @close="emit('close')"
    id="unity2-app"
    class="overflow-sm-auto overflow-md-hidden"
  >
    <MDropdownNavListItem
      :url="' '"
      :link-content="t('common.logout')"
      @click.prevent="logout()"
      class="px-4 order-last"
    >
      <MMaterialSymbol
        size="24"
        :symbol-name="MaterialSymbolNames.LogOut"
        class="u-color-black me-2_5"
      />
    </MDropdownNavListItem>
  </MDropdownNavList>
</template>
<script setup lang="ts">
import { EventBus, EventLogout } from '@/utils/EventBus';
import { useI18n } from 'vue-i18n';
import MDropdownNavList from '../common/list/MDropdownNavList.vue';
import MDropdownNavListItem from '../common/list/MDropdownNavListItem.vue';
import MMaterialSymbol from '@/components/common/icons/MMaterialSymbol.vue';
import { MaterialSymbolNames } from '@/models/MaterialSymbolNames';

const { t } = useI18n();

const emit = defineEmits<{
  (e: 'close'): void;
}>();

function logout() {
  EventBus.emit(EventLogout);
}
</script>
