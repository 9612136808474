import type { Customer } from '@/features/sodraEl/models/Customer';
import { BaseServiceDefinition } from './BaseService';
import type { LoginResult } from '@/models/auth/LoginResult';

class AuthService extends BaseServiceDefinition {
  public constructor() {
    super();
  }

  public async login(): Promise<LoginResult> {
    return (await this.post('/auth/login')).data;
  }

  public async callback(grandidsession: string): Promise<Customer> {
    return (await this.post('/auth/callback', undefined, { grandidsession }))
      .data;
  }

  public async getCurrentCustomer(): Promise<Customer> {
    return (await this.get('/auth/current')).data;
  }

  public async logout(): Promise<void> {
    await this.post('/auth/logout');
  }
}

const authService: AuthService = new AuthService();

export default authService;
