import type { RouteMeta, RouteRecordRaw } from 'vue-router';
import { RouteNames } from './routeNames';

const sodraElDefaultRouteMeta: RouteMeta = {
  track: true,
  title: 'sodraEl.routes.home',
  authRequirements: {
    allowAnonymous: false,
  },
};

const SodraElHomeRouteDefinitions: RouteRecordRaw[] = [
  {
    path: '/statistik',
    component: () => import('@features/sodraEl/views/StatisticsPage.vue'),
    name: RouteNames.Statistics,
    meta: {
      ...sodraElDefaultRouteMeta,
      name: RouteNames.Statistics,
    },
  },
  {
    path: '/fakturor',
    component: () => import('@features/sodraEl/views/InvoicesPage.vue'),
    name: RouteNames.Invoices,
    meta: {
      ...sodraElDefaultRouteMeta,
      name: RouteNames.Invoices,
    },
  },
  {
    path: '/kunduppgifter',
    component: () => import('@features/sodraEl/views/CustomerDetailsPage.vue'),
    name: RouteNames.CustomerDetails,
    meta: {
      ...sodraElDefaultRouteMeta,
      name: RouteNames.CustomerDetails,
    },
  },
  {
    path: '/avtal',
    component: () => import('@features/sodraEl/views/AgreementsPage.vue'),
    name: RouteNames.Agreements,
    meta: {
      ...sodraElDefaultRouteMeta,
      name: RouteNames.Agreements,
    },
  },
  {
    path: '/kontakt',
    component: () => import('@features/sodraEl/views/ContactPage.vue'),
    name: RouteNames.Contact,
    meta: {
      ...sodraElDefaultRouteMeta,
      name: RouteNames.Contact,
    },
  },
];

export const routes: RouteRecordRaw[] = SodraElHomeRouteDefinitions;
