window.addEventListener(
  'CookieInformationConsentGiven',
  function () {
    if (
      !(window as any).CookieInformation.getConsentGivenFor(
        'cookie_cat_statistic',
      ) ||
      !(window as any).CookieInformation.getConsentGivenFor(
        'cookie_cat_marketing',
      )
    ) {
      const cookies = document.cookie.split('; ');
      for (const cookie of cookies) {
        const name = cookie.split('=')[0];
        if (name.startsWith('_hj')) {
          document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=sodra.com`;
        }
      }
      if ((window as any).hj) {
        location.reload();
      }
    }
  },
  false,
);
