<template>
  <li class="dropdown-nav__item d-flex">
    <a v-if="url" :href="url" class="dropdown-nav__link t-body-large">
      <slot></slot>
      {{ linkContent }}
    </a>

    <router-link
      v-else-if="routeName"
      :to="{ name: routeName }"
      class="dropdown-nav__link t-body-large"
      disable-tooltip
    >
      <slot></slot>
      {{ linkContent }}
    </router-link>

    <slot v-else></slot>
  </li>
</template>

<script setup lang="ts">
import { toRefs } from 'vue';

const props = defineProps<{
  linkContent?: string;
  url?: string;
  routeName?: string;
}>();

const { linkContent, url } = toRefs(props);
</script>

<style scoped lang="scss">
.dropdown-nav {
  &__item {
    align-items: center;
    height: 48px;
    border-top: 1px solid $sodra-black-10;

    @media (max-width: $unity-lg-breakpoint) {
      text-align: left;
    }
  }

  &__link {
    color: $sodra-black-60;

    @media (max-width: $unity-lg-breakpoint) {
      display: block;
      width: 100%;
      height: auto;
    }
  }
}
</style>
