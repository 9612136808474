import { Config } from './utils/Config';
import { Logger } from './utils/Logger';
import { createApp } from 'vue';
import { createPinia } from 'pinia';
import App from './App.vue';
import { router } from './router';

import i18n from '@/i18n';
import veeValidate from '@/plugins/vee-validate';
import highchartsVue from './plugins/highcharts-vue';
import vueDatepicker from './plugins/vue-datepicker';
import VueVirtualScroller from 'vue-virtual-scroller';

Config.initialize();
Logger.initialize();

const app = createApp(App);

app.config.errorHandler = (err, instance, info) => {
  if (import.meta.env.PROD) {
    // eslint-disable-next-line no-console
    console.error(`Error in ${info}: "${(err as any).toString()}"`);
  }

  // eslint-disable-next-line no-console
  console.error(err);
};

app.use(veeValidate);
app.use(highchartsVue);
app.use(vueDatepicker);
app.use(createPinia());
app.use(router);
app.use(i18n);
app.use(VueVirtualScroller);

app.mount('#app');
