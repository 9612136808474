import type { RouteRecordRaw } from 'vue-router';
import { authRequirementsCommonPublic } from './authRequirements';
import NotFoundPage from '@/views/NotFoundPage.vue';
import { RouteNames } from './routeNames';
import { routes as sodraElRoutes } from '@/features/sodraEl/router';

const defaultDocumentTitle = import.meta.env.VITE_APP_DOCUMENT_DEFAULT_TITLE;

export const HomeRouteDefinition: RouteRecordRaw = {
  path: '/',
  component: () => import('@/views/HomePage.vue'),
  name: RouteNames.Home,
  meta: {
    track: true,
    name: defaultDocumentTitle,
    title: defaultDocumentTitle,
    authRequirements: authRequirementsCommonPublic,
  },
  children: sodraElRoutes,
};

export const AuthenticationRouteDefinition: RouteRecordRaw = {
  path: '/authentication',
  component: () => import('@/views/AuthenticationPage.vue'),
  name: RouteNames.Authentication,
  meta: {
    authRequirements: authRequirementsCommonPublic,
  },
};

export const NotFoundRouteDefinition: RouteRecordRaw = {
  path: '/:pathMatch(.*)*',
  component: NotFoundPage,
  name: RouteNames.NotFound,
  meta: {
    authRequirements: authRequirementsCommonPublic,
  },
};
