<template>
  <div>
    <MBanner image-url="/img/hero_highres-min.jpg" />

    <div class="container-fluid container-fluid--page">
      <div class="row">
        <div class="col-12 u-text-center">
          <h1 class="t-heading-3" v-html="t('notFoundPage.title')"></h1>
          <p class="mt-4">
            <router-link to="/">{{ t('notFoundPage.goBack') }}</router-link>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import MBanner from '@/components/common/MBanner.vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
</script>
