export enum MaterialSymbolNames {
  AddCircle = 'add_circle',
  ArrowUp = 'expand_less',
  ArrowDown = 'expand_more',
  ArrowBack = 'arrow_back',
  ArrowRight = 'keyboard_arrow_right',
  Close = 'close',
  Contact = '3p',
  Contribution = 'control_point_duplicate',
  Account = 'manage_accounts',
  Group = 'group',
  LogOut = 'logout',
  UseAs = 'supervisor_account',
  Menu = 'menu',
  Forest = 'forest',
  Overview = 'visibility',
  Phone = 'phone',
  Email = 'email',
  Environment = 'eco',
  NestEcoLeaf = 'nest_eco_leaf',
  Cookies = 'cookie',
  Assignment = 'assignment',
  Help = 'help',
  Location = 'near_me',
  Copy = 'content_copy',
  ExternalLink = 'open_in_new',
  Sort = 'sort',
  Filter = 'tune',
  Search = 'search',
  Article = 'article',
  Info = 'info',
  Edit = 'edit',
  EditSquare = 'edit_square',
  CheckCircle = 'check_circle',
  Add = 'add',
  Delete = 'delete',
  Expand = 'expand_all',
  RemoveCircle = 'do_not_disturb_on',
  Done = 'done',
  Calendar = 'calendar_month',
  Error = 'error',
  Economy = 'account_balance_wallet',
  OrderApprove = 'order_approve',
  Document = 'description',
  Chat = 'chat',
  Refresh = 'refresh',
  Globe = 'globe',
  Upload = 'upload',
  Lock = 'lock',
  Download = 'download',
  Remove = 'remove',
}
