{
  "sodraEl": {
    "routes": {
      "home": "@:sodraEl.common.pageName"
    },
    "common": {
      "pageName": "Södra El",
      "phoneNumber": "0470-890 20",
      "loading": "Laddar...",
      "error": {
        "tryAgain": "Försök igen"
      }
    },
    "views": {
      "home": {
        "title": "@:sodraEl.common.pageName",
        "navigation": {
          "statistics": "Energistatistik",
          "invoices": "Fakturor",
          "customerDetails": "Kunduppgifter",
          "agreements": "Avtal",
          "nonCustomer": "Teckna avtal",
          "contact": "Kontakta Södra El"
        },
        "error": {
          "backToHome": "Tillbaka till startsidan"
        }
      },
      "customerDetails": {
        "title": "Kunduppgifter för Södra El",
        "name": "Namn",
        "customerNumber": "Kundnummer hos Södra El",
        "address": "Adress",
        "postalCode": "Postnummer",
        "city": "Ort",
        "phoneNumber": "Telefon",
        "mobileNumber": "Mobil",
        "email": "E-post",
        "contactLink": {
          "wrongDetails": "Stämmer inte dina kontaktuppgifter?",
          "help": "så hjälper vi dig.",
          "link": "Kontakta Södra El"
        }
      },
      "invoicePage": {
        "productionTitle": "Produktion: ",
        "consumptionTitle": "Förbrukning: ",
        "refersTo": "Avser",
        "period": "Period",
        "dueDate": "Förfallodatum",
        "paymentDate": "Utbetalningsdatum",
        "status": "Status",
        "amount": "Total (SEK)",
        "remainingAmount": "Kvarstående saldo (SEK)",
        "facilityId": "Anläggnings-ID: ",
        "downloadError": "Något gick fel när fakturan skulle laddas ner.",
        "showMoreInvoices": "Visa fler fakturor",
        "noInvoices": "Det finns inga fakturor att visa.",
        "loadMoreError": "Det gick inte läsa in fler fakturor."
      },
      "statistics": {
        "loading": "Laddar..."
      }
    },
    "components": {
      "energyStatistics": {
        "consumptionTitle": "Se din elförbrukning över tid",
        "productionTitle": "Se din elproduktion över tid",
        "consumptionText": "Grafen visar din elförbrukning över tid i kWh. Nedanför kan du välja vilket intervall och mellan vilka datum du vill se din elförbrukning.",
        "productionText": "Grafen visar din elproduktion över tid i kWh. Nedanför kan du välja vilket intervall och mellan vilka datum du vill se din elproduktion.",
        "facilityId": "Anläggnings-ID:",
        "modes": {
          "Year": "År",
          "Month": "Månad",
          "Week": "Vecka",
          "Day": "Dag",
          "Hour": "Timmar"
        },
        "date": "Datum",
        "rangedDate": "Datum (maximalt tidsspann är {0})",
        "invalidDates": {
          "updated": "Slutdatumet anpassades utifrån det tillåtna intervallet. ",
          "invalidRange": "Maximalt tidsspann är {0}.",
          "mode": {
            "Month": "{0} månader",
            "Week": "{0} veckor",
            "Day": "{0} dagar",
            "Hour": "{0} dag"
          }
        },
        "noDataYet": "Det finns ingen statistik tillgänglig för det valda intervallet"
      },
      "energyStatisticsChart": {
        "error": "Ett fel uppstod vid hämtningen av energistatistik för den valda perioden. Prova att ladda om sidan eller justera perioden."
      },
      "facilityPicker": {
        "chooseFacility": "Välj anläggning"
      },
      "agreementDetails": {
        "consumptionTitle": "Förbrukningsavtal",
        "productionTitle": "Produktionsavtal",
        "name": "Mitt avtal",
        "paymentMethod": "Hur får jag min faktura",
        "address": "Adress",
        "biddingArea": "Elområde",
        "facility": "Anläggnings-ID",
        "startDate": "Avtalet startade",
        "endDate": "Avtalsperiod",
        "termOfNoticeDescription": "Uppsägningstid",
        "paymentMethods": {
          "eInvoice": "E-faktura",
          "email": "E-post",
          "paper": "Pappersfaktura",
          "peppol": "Peppol",
          "unkown": "Okänt"
        }
      },
      "invoiceDetailsRow": {
        "delayed": "Försenad",
        "unpayed": "Obetald",
        "notPayedOut": "Ej utbetald",
        "payed": "Betald",
        "downloadInvoice": "Ladda ner faktura"
      },
      "contactInfo": {
        "title": "Kontakta Södra El",
        "text": "Vid frågor kring Södra El och våra tjänster är du välkommen att ringa eller maila kundtjänst.",
        "openDays": "Måndag - Fredag",
        "openHours": "09:00 - 16:00",
        "closed": "Röda dagar stängt",
        "sodraElLink": "@:sodraEl.common.pageName"
      }
    }
  }
}
