{
  "app": {
    "error": {
      "title": "Hoppsan! Vi stötte på något problem",
      "info": "Prova att ladda om sidan eller kom tillbaka igen om en stund."
    }
  },
  "header": {
    "to_sodra": "Till sodra.com"
  },
  "components": {
    "filter": {
      "filterBar": {
        "reset": "Rensa alla filter"
      },
      "filterModal": {
        "title": "Filtrera",
        "reset": "Rensa alla filter"
      }
    },
    "notFound": {
      "header": "Inget innehåll hittades.",
      "text": "Det gick inte att hitta detta innehåll. Det kan bero på att det antingen tagits bort eller av någon annan anledning inte kunnat läsas in."
    },
    "standardError": {
      "default": {
        "title": "Innehållet kunde inte hämtas",
        "text": "Det gick inte att hämta detta innehåll. Det kan bero på att det antingen tagits bort eller av någon annan anledning inte kunnat läsas in."
      },
      "404": {
        "title": "404 - Sidan kan inte hittas",
        "text": "Det gick inte att hitta denna sidan. Det kan bero på att det antingen tagits bort eller av någon annan anledning inte kunnat läsas in."
      }
    },
    "phoneNumberInput": {
      "placeholder": "Ange telefonnummer"
    }
  },
  "notFoundPage": {
    "title": "Hoppsan! Sidan <br>kunde inte hittas...",
    "goBack": "Gå till startsidan."
  },
  "unauthenticated": {
    "banner": {
      "title": "Välkommen till Södra El",
      "bullets": [
        "Håll koll på din energianvändning",
        "Förnya ditt elavtal",
        "Se dina fakturor",
        "Se dina avtalsuppgifter"
      ]
    },
    "authError": {
      "NotFound": "Du saknar avtal hos Södra El. Om ditt elavtal står på ett företag kan du komma åt Mina Sidor genom att logga in med lösenord på Min Skogsgård, förutsatt att företaget är medlem i Södra.",
      "Other": "Något gick fel vid inloggningen. Försök igen senare eller kontakta kundservice."
    },
    "login": {
      "header": "Logga in på mina sidor",
      "text": "På mina sidor kan du hålla koll på din energianvändning, förnya ditt elavtal, se dina fakturor eller dina avtalsuppgifter.",
      "bankid_text": {
        "mobile": "Logga in med BankID",
        "desktop": "Fortsätt till inloggning med BankID"
      },
      "org_text": {
        "mobile": "Företagskund? Logga in på Min Skogsgård",
        "desktop": "Är du företagskund? Logga in på Min Skogsgård"
      }
    },
    "newCustomer": {
      "title": "Saknar du elavtal?",
      "description": "Med Södra El kan du välja energi som är lokalt producerad och helt utan klimatavtryck - dessutom till ett förmånligt pris. Tack vare våra medlemmar, deras skogar och att vi har hållbara industrier som producerar mer el än de förbrukar. Både klimatsmart och smart för ekonomin – nu behöver du inte välja antingen eller.",
      "climate": "Det är bra för klimatet.",
      "wallet": "Det är bra för din plånbok.",
      "forest": "Närproducerad el från våra medlemmars skogar.",
      "price": "Priset är förmånligt över tid så du behöver inte längre jämföra elbolag.",
      "change": "Det är enkelt att byta till - vi hjälper dig!",
      "goToNewAgreement": "Läs mer och teckna avtal"
    }
  },
  "contactLink": {
    "title": "Kontakta oss"
  },
  "cookieButton": {
    "title": "Hantera cookies"
  },
  "alts": {
    "bankId": "Bank ID",
    "sodraLogo": "Södra logotyp",
    "proxyStatusIcon": {
      "creating": "ikon - intressent skapas",
      "sent": "ikon - skickad",
      "active": "ikon - aktiv",
      "cancelled": "ikon - annulerad"
    }
  },
  "common": {
    "cancel": "Avbryt",
    "choose": "Välj",
    "logout": "Logga ut",
    "missingAccess": "Du saknar behörighet för det här",
    "moreInfo": "Mer information"
  }
}
