<template>
  <div class="c-carousel mb-4">
    <div class="c-carousel__track">
      <div class="c-carousel__slide">
        <div
          class="c-banner c-banner--background-size-cover c-banner--height-pixels-200"
          :style="'background-image:url(' + imageUrl + ');'"
        >
          <template v-if="$slots.default">
            <div
              class="c-banner__overlay c-banner__overlay--dark-left-to-right"
            ></div>

            <div class="container-fluid d-flex">
              <div
                class="c-banner__text c-banner__text--color-white c-banner__text--vertical-align-center"
              >
                <slot></slot>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { toRefs } from 'vue';

const props = defineProps<{
  imageUrl: string;
}>();

const { imageUrl } = toRefs(props);
</script>
