import { defineStore } from 'pinia';
import { reactive, toRefs } from 'vue';

interface State {
  initialized: boolean;
  initializeError: boolean;
}

export const startupStoreId = 'startup';

export const useStartupStore = defineStore(startupStoreId, () => {
  const state: State = reactive({
    initialized: false,
    initializeError: false,
  });

  const actions = {
    isInitialized(): void {
      state.initialized = true;
    },
    error(): void {
      state.initializeError = true;
    },
  };

  return {
    ...toRefs(state),
    ...actions,
  };
});
