<template>
  <Teleport to="body">
    <div
      id="unity2-app"
      class="o-loader o-loader--fullscreen"
      :class="{ customClass: customClass }"
    >
      <div v-if="text" class="mt-2">
        {{ text }}
      </div>
    </div>
  </Teleport>
</template>

<script setup lang="ts">
import { toRefs } from 'vue';

const props = defineProps<{
  text?: string;
  customClass?: string;
}>();

const { text, customClass } = toRefs(props);
</script>
