import type { AuthRequirements } from '@/router/authRequirements';
import { useAuthStore } from '@/stores/authStore';
import { Logger } from '@/utils/Logger';

export enum ValidationError {
  NotAuthenticated,
}

const validate = (
  requirements: AuthRequirements,
): { valid: boolean; error: ValidationError | undefined } => {
  if (requirements.allowAnonymous !== true) {
    const authStore = useAuthStore();
    if (!authStore.isAuthenticated) {
      Logger.debug(
        'Requirement do not have public access and user is not authenticated.',
        requirements,
      );
      return { valid: false, error: ValidationError.NotAuthenticated };
    }
  }

  return { valid: true, error: undefined };
};

export const Permissions = {
  validate,
};
